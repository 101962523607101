export const services = {
  servicesPageMainTitle: "TREATMENT MENU",
  servicesPageLearnMoreButtonText: "READ MORE",
  servicesPageButtonText: "BOOK NOW",
  items: [
    {
      mainImage: "Initial1.png",
      mainImageDescription: "...",
      serviceTitle: "Initial Assessment & Treatment",
      serviceId: "InitialAssessment",
      servicePriceText: "",
      servicePrice: "70",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Initial2.png",
        imageDescription: "...",
        serviceMainDescription:
          "A thorough consultation and treatment to tailor the perfect care plan.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "60 Mins -",
          price: "70",
        },
  
      ],
    },
    {
      mainImage: "PHOTO-2023-11-05-14-05-44.jpg",
      mainImageDescription: "...",
      serviceTitle: "Gentle Back Massage",
      serviceId: "RelaxingBackMassage",
      servicePriceText: "From",
      servicePrice: "40",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "PHOTO-2023-11-05-14-05-46.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Gentle Back Massage is a soothing, tension-relieving back massage for relaxation.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "40",
        },
        {
          mins: "45 Mins -",
          price: "50",
        },
        {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    
    {
      mainImage: "439a14f3-e4d7-4e16-bc49-58e8ea9cfc02.JPG",
      mainImageDescription: "...",
      serviceTitle: "Deep Tissue Massage",
      serviceId: "DeepTissueMassage",
      servicePriceText: "From",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "6afcdfec-f38e-46b0-af77-a86518ccd09a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Deep Tissue Massage uses intense pressure to release chronic tension and knots. Available for the back, legs, and as an urgent appointment.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "Back 60 Mins -",
          price: "70",
        },
        {
          mins: "Legs 60 Mins -",
          price: "60",
        },
        {
          mins: "Urgent 55 Mins -",
          price: "85",
        },
      ],
    },
    {
      mainImage: "9d5f5a6b-9c7a-4ac2-8a7e-bc4f6e9030e2.JPG",
      mainImageDescription: "...",
      serviceTitle: "Relaxing Full Body Massage",
      serviceId: "FullBodyMassage",
      servicePriceText: "From",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "a030e70a-c62e-40e9-880b-d5db7cc1c422.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Full Body Massage is a deeply relaxing treatment for full-body stress relief.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
        {
          mins: "90 Mins -",
          price: "90",
        },
      ],
    },
    
    {
      mainImage: "61c08afb-3049-4b53-b840-803e60347f61.JPG",
      mainImageDescription: "...",
      serviceTitle: "Neck & Shoulders Massage",
      serviceId: "NeckShouldersMassage",
      servicePriceText: "",
      servicePrice: "55",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "fea454cb-52c4-465b-bc70-fec6d202733a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Neck and Shoulders Massage focuses on built-up tension in the upper body.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "45 Mins -",
          price: "55",
        },
      ],
    },
    {
      mainImage: "Head Spa.png",
      mainImageDescription: "...",
      serviceTitle: "Head Spa Experience",
      serviceId: "HeadSpaExperience",
      servicePriceText: "From",
      servicePrice: "45",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Head Spa 4.png",
        imageDescription: "...",
        serviceMainDescription:
          "A luxurious scalp and hair treatment that deeply cleanses, nourishes, and relaxes the mind.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "Mini (35 minutes)",
          price: "45",
        },
        {
          mins: "Midi (60 minutes)",
          price: "70",
        },
        {
          mins: "Maxi (90 minutes)",
          price: "100",
        },
      ],
    },
    {
      mainImage: "Whisper1.png",
      mainImageDescription: "...",
      serviceTitle: "Whisper Touch Massage",
      serviceId: "Whisper Touch Massage",
      servicePriceText: "From",
      servicePrice: "40",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Whisper2.png",
        imageDescription: "...",
        serviceMainDescription:
          "Whisper Touch Massage is an ultra-light massage designed to induce deep relaxation.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "'Moment' 30 Mins -",
          price: "40",
        },
        {
          mins: "'Eternity' 60 Mins -",
          price: "60",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
   {
      mainImage: "Foot1.png",
      mainImageDescription: "...",
      serviceTitle: "Ultimate Foot Spa",
      serviceId: "UltimateFootSpa",
      servicePriceText: "",
      servicePrice: "55",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Foot2.png",
        imageDescription: "...",
        serviceMainDescription:
          "Ultimate Foot Spa includes a pampering foot soak, exfoliation, and massage.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "60 Mins -",
          price: "55",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    {
      mainImage: "d1b39fcf-4400-4418-acb2-b7de8c9a5931.JPG",
      mainImageDescription: "...",
      serviceTitle: "Warm Rattan Massage",
      serviceId: "wormRattanMassage",
      servicePriceText: "",
      servicePrice: "75",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "5049ffef-257c-42f2-95a9-66a223c1a86f.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Warm Rattan Massage is a unique heat-based therapy designed to ease muscular stiffness",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
               {
          mins: "60 Mins -",
          price: "75",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    
    {
      mainImage: "hotStoneMassage.png",
      mainImageDescription: "...",
      serviceTitle: "Hot Stone Massage",
      serviceId: "hotStoneMassage",
      servicePriceText: "",
      servicePrice: "75",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "3821a820-c606-45bd-bc6c-59bd40d5c972.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Hot Stone Massage uses heated stones to melt away tension and promote deep relaxation.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
      
        {
          mins: "60 Mins -",
          price: "75",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    
        {
      mainImage: "45050858-229d-4d6d-a732-56edddbd765e.JPG",
      mainImageDescription: "...",
      serviceTitle: "Lymphatic Drainage Massage",
      serviceId: "LymphaticDrainageMassage",
      servicePriceText: "",
      servicePrice: "75",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "e9c6328e-ada8-4a0e-bf4f-e53ef5e43fd5.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Lymphatic Drainage Massage is a gentle technique that promotes detoxification and circulation.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "75",
        },
      ],
    },
    {
      mainImage: "2ee3f39a-aef9-4c5c-ab19-8f3065fc9ee8.JPG",
      mainImageDescription: "...",
      serviceTitle: "Sports Massage",
      serviceId: "SportsMassage",
      servicePriceText: "From",
      servicePrice: "45",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "PHOTO-2023-11-05-21-47-14.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Sports Massage provides targeted deep muscle work to aid recovery and performance.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "45",
        },
        {
          mins: "60 Mins -",
          price: "70",
        },
      ],
    },
  
    {
      mainImage: "FCup1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Fire Cupping & Tension Relief",
      serviceId: "FireCupTensionRelief",
      servicePriceText: "",
      servicePrice: "55",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Fcup2.jpg",
        imageDescription: "...",
        serviceMainDescription:
        "Fire Cupping & Tension Relief uses cupping therapy to ease deep muscle tension and promote relaxation.",
      serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "55",
        },
      ],
    },
    {
      mainImage: "903db8dd-265b-4a86-95c1-cf12f447d1fa.JPG",
      mainImageDescription: "...",
      serviceTitle: "Indian Head Massage",
      serviceId: "IndianHeadMassage",
      servicePriceText: "",
      servicePrice: "40",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "c6a0c3fb-df65-447b-8103-866aafc8d61a.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Indian Head Massage is a calming head, neck, and shoulder massage designed to relieve stress and tension.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "40",
        },
      
      ],
    },
    {
      mainImage: "dbc03a4e-effa-4458-af08-cda3daf7dab4.JPG",
      mainImageDescription: "...",
      serviceTitle: "Signature Mini Facial",
      serviceId: "MiniFacial",
      servicePriceText: "",
      servicePrice: "40",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "d369e93e-9f31-44e7-bd4a-6909bad7f0f0.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Mini Facial is a refreshing, skin-boosting facial.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "30 Mins -",
          price: "40",
        },
      ],
    },
   
    {
      mainImage: "SMFHM1.png",
      mainImageDescription: "...",
      serviceTitle: "Signature Mini Facial with Head Massage",
      serviceId: "SignatureMiniFacialHeadMassage",
      servicePriceText: "",
      servicePrice: "70",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "SMFHM2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Mini Facial with Head Massage combines a relaxing facial with a scalp massage.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "70",
        },
      ],
    },
    {
      mainImage: "32f44f5c-652b-404c-b384-95bfe7d9b50f.JPG",
      mainImageDescription: "...",
      serviceTitle: "Signature Head & Feet Treatment",
      serviceId: "FabulousFeetHeadMassage",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "b8d206e9-63ff-4020-ab1c-68ee7b18ea1b.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Relax Massage Studio Signature Head & Feet Treatment is a dual-focus treatment for full relaxation.",
        serviceSecDescriptions: [

          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    {
      mainImage: "SignatureBackFacial1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Signature Back 'Facial'",
      serviceId: "SignatureBackFacial",
      servicePriceText: "",
      servicePrice: "65",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "SignatureBackFacial2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Signature Back Facial is a deep cleanse and exfoliation for the back.",
          serviceSecDescriptions: [
          
             // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "65",
        },
      ],
    },
             
    {
      mainImage: "1858171f-2016-482f-a9bf-4170b2a48ffb.JPG",
      mainImageDescription: "...",
      serviceTitle: "Paraffin Wax Hands Treatment",
      serviceId: "ParaffinWaxHandsTreatment",
      servicePriceText: "",
      servicePrice: "20",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "a64b86dd-2c75-4b77-bf32-9f613f4c8a81.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Paraffin Wax Hands Treatment is a warm wax treatment for soft, nourished hands.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "",
          price: "20",
        },
        // {
        //   mins: "",
        //   price: "",
        // },
      ],
    },
    {
      mainImage: "Reflex1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Reflexology (Feet)",
      serviceId: "FeetReflexology",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "Reflex2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Reflexology (Feet) is a pressure point therapy designed to enhance relaxation and overall wellness.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "60 Mins -",
          price: "60",
        },
      ],
    },
    {
      mainImage: "99991752-89a5-4133-b5a1-aee99546937b.JPG",
      mainImageDescription: "...",
      serviceTitle: "Reiki",
      serviceId: "reikiHealing",
      servicePriceText: "",
      servicePrice: "60",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "eb898e44-3e3c-4cf1-b7b9-ae5d64808f85.JPG",
        imageDescription: "...",
        serviceMainDescription:
          "Reiki Session is a holistic energy healing treatment that helps restore balance and harmony.",
        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },
        ],
      },
      servicesPricesList: [
        {
          mins: "45-60 Mins -",
          price: "60",
        },
      ],
    },
    
    {
      mainImage: "HomeVisits1.jpg",
      mainImageDescription: "...",
      serviceTitle: "Home Visit",
      serviceId: "HomeVisit",
      servicePriceText: "",
      servicePrice: "300",
      serviceCurrency: "£",
      serviceDescriptions: {
        image: "HomeVisits2.jpg",
        imageDescription: "...",
        serviceMainDescription:
          "Luxury massage in the comfort of your own home. Home Visit is a bespoke treatment brought to your location.",        serviceSecDescriptions: [
          // {
          //   title: "",
          //   paragraph: "",
          // },

        ],
      },
      servicesPricesList: [
        {
          mins: "90 Mins -",
          price: "300",
        },
      ],
    },
    
   
    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Back Massage Mixed Techniques",
    //   serviceId: "BackMassageMixedTechniques",
    //   servicePriceText: "From",
    //   servicePrice: "50",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //   servicesPricesList: [
    //     // {
    //     //   mins: "",
    //     //   price: "",
    //     // },
    //   ],
    // },

    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Herbal Stamp Full Body Massage",
    //   serviceId: "HerbalStampFullBodyMassage",
    //   servicePriceText: "From",
    //   servicePrice: "65",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "footReflexology.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Reflexology (Feet)",
    //   serviceId: "ReflexologyFeet",
    //   servicePriceText: "From",
    //   servicePrice: "50",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Foot reflexology is a type of reflexology that focuses on applying pressure to specific points on the feet to improve overall health and well-being.",
    //     serviceSecDescriptions: [
    //       {
    //         title: "Benefits of Foot Reflexology",
    //         paragraph:
    //           "Foot reflexology offers a multitude of benefits, including stress reduction, enhanced relaxation, improved circulation, strengthened immunity, pain relief, and an overall sense of well-being.",
    //       },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Pregnancy Full Body",
    //   serviceId: "PregnancyFullBody",
    //   servicePriceText: "From",
    //   servicePrice: "50",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "exfoliation.png",
    //   mainImageDescription: "...",
    //   serviceTitle: "Rejuvenating Body Exfoliation",
    //   serviceId: "RejuvenatingBodyExfoliation",
    //   servicePriceText: "From",
    //   servicePrice: "60",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "exfoliation.png",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
    // {
    //   mainImage: "",
    //   mainImageDescription: "...",
    //   serviceTitle: "",
    //   serviceId: "",
    //   servicePriceText: "",
    //   servicePrice: "",
    //   serviceCurrency: "£",
    //   serviceDescriptions: {
    //     image: "",
    //     imageDescription: "...",
    //     serviceMainDescription:
    //       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, ipsum molestiae.",
    //     serviceSecDescriptions: [
    //       // {
    //       //   title: "",
    //       //   paragraph: "",
    //       // },
    //     ],
    //   },
    //  servicesPricesList: [
    //    {
    //     mins: "",
    //     price: "",
    //    },
    //  ],
    // },
  ],
};
